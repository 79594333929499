/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import Rollbar from "common/utils/Rollbar"

Rollbar.init()

// Import all the third party stuff
import React from "react"
import "sanitize.css/sanitize.css"
import "common/i18n"
// eslint-disable-next-line

import confirmDialog from "organizers/utils/confirmDialog"
import requestValue from "organizers/utils/requestValue"

import ConnectedApp from "common/ConnectedApp"

// Import CSS reset and Global Styles
import theme, { muiTheme, jss } from "./theme"

import App from "organizers/containers/App"
import logEventConfig from "organizers/utils/logEventConfig"
import DashboardTypeContext from "common/DashboardTypeContext"
import configureStore from "./configureStore"

window.__authTokenName = "X-Organizer-Token"
window.__authEmailName = "X-Organizer-Email"

export default function OrganizersApp() {
  return (
    <ConnectedApp
      authTokenName={"X-Organizer-Token"}
      authEmailName={"X-Organizer-Email"}
      configureStore={configureStore}
      jss={jss}
      theme={theme}
      muiTheme={muiTheme}
      logEventConfig={logEventConfig}
      confirmDialog={confirmDialog}
      requestValue={requestValue}
    >
      <DashboardTypeContext.Provider value={"organizers"}>
        <App />
      </DashboardTypeContext.Provider>
    </ConnectedApp>
  )
}
